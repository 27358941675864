import { createApp } from 'vue';
import mitt from 'mitt';
import VueGtag from "vue-gtag";

import router from './router.js';
import store from './store/index.js';
import App from './App.vue';

// static files
// CSS
// import "./assets/css/slick.css";
import "./assets/css/main.css?ver=1.19";
import "./assets/css/responsive.css?ver=1.19";
import "./assets/css/custom.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const emitter = mitt();
const app = createApp(App)

app.use(router);
app.use(store);
app.use(VueSweetalert2);

app.config.globalProperties.emitter = emitter;

app.use(VueGtag, {
  config: { id: "G-XFVLGBK57J" }
});

app.mount('#app');
