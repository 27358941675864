import axios from "axios";

export default {
  async loginUser(context, payload) {
    try {
      let baseUrl = context.rootGetters.getDevBaseURL;
      const response = await axios.post(`${baseUrl}/account/login`, {
        email: payload.email,
        password: payload.password
      });

      if (response && response.status === 200 && response.data.code == 200) {
        const data = response.data.data;
        const measurememntResponse = await axios.get(`${baseUrl}/measurement/get`, {
          headers: {
             Authorization: "Bearer " + data.token
          }
        });
        // console.log("measurememntResponse------");
        // console.log(measurememntResponse);
        if (measurememntResponse && measurememntResponse.status === 200 && measurememntResponse.data.code == 200) {
          // console.log(measurememntResponse.data);
          localStorage.setItem('waist', measurememntResponse.data.data.waist);
          localStorage.setItem('hip',  measurememntResponse.data.data.hip);
          localStorage.setItem('thigh',  measurememntResponse.data.data.thigh);
          localStorage.setItem('inseam',  measurememntResponse.data.data.inseam);
          localStorage.setItem('email',  payload.email);
          localStorage.setItem('measurementType',  "inches");

        }

        const expirationDate = new Date().getTime() + (10 * 24 * 60 * 60 * 1000); // 10 days TTL
        // const expirationDate = new Date().getTime() + (2 * 60 * 1000);
        // store into localstorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('_id', data._id);
        localStorage.setItem('name', data.name);
        localStorage.setItem('expiresIn', expirationDate);
        context.commit('setUserFromAuth', {
          _id: data._id,
          token: data.token,
          name: data.name,
        });
      }
      return {
        message: response.data.message,
        code: response.data.code
      };
    } catch (err) {
      console.log(err);
      alert("Something went wrong.");
    }
  },
  async signupUser(context, payload) {
    try {
      let baseUrl = context.rootGetters.getDevBaseURL;
      const response = await axios.post(`${baseUrl}/account/signup`, {
        name: payload.name,
        email: payload.email,
        waist: payload.waist,
        hip: payload.hip,
        thigh: payload.thigh,
        inseam: payload.inseam,
        password: payload.password
      });

      // console.log("response-----------------------------------");
      // console.log(response);

      if (response && response.status === 200 && response.data.code == 200) {
        const data = response.data.data;
        const measurememntResponse = await axios.get(`${baseUrl}/measurement/get`, {
          headers: {
             Authorization: "Bearer " + data.token
          }
        });
        // console.log("measurememntResponse------");
        // console.log(measurememntResponse);
        if (measurememntResponse && measurememntResponse.status === 200 && measurememntResponse.data.code == 200) {
          // console.log(measurememntResponse.data);
          localStorage.setItem('waist', measurememntResponse.data.data.waist);
          localStorage.setItem('hip',  measurememntResponse.data.data.hip);
          localStorage.setItem('thigh',  measurememntResponse.data.data.thigh);
          localStorage.setItem('inseam',  measurememntResponse.data.data.inseam);
          localStorage.setItem('email',  payload.email);
          localStorage.setItem('measurementType',  "inches");
        }

        const expirationDate = new Date().getTime() + (10 * 24 * 60 * 60 * 1000); // 10 days TTL
        // const expirationDate = new Date().getTime() + (2 * 60 * 1000); // 2 minutes TTL
        localStorage.setItem('token', data.token);
        localStorage.setItem('_id', data._id);
        localStorage.setItem('name', data.name);
        localStorage.setItem('expiresIn', expirationDate);
        context.commit('setUserFromAuth', {
          _id: data._id,
          token: data.token,
          name: data.name,
        });
      }
      return {
        message: response.data.message,
        code: response.data.code
      };
    } catch (err) {
      console.log(err);
      alert("Something went wrong.");
    }
  },
  autoLogin (context) {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('_id');
    const name = localStorage.getItem('name');
    const expiresIn = localStorage.getItem('expiresIn');
    const now = new Date();

    if (token) {
      if (now.getTime() > expiresIn) {
        localStorage.removeItem('token');
        localStorage.removeItem('_id');
        localStorage.removeItem('name');
        localStorage.removeItem('expiresIn');

        context.commit('setUserFromAuth', {
          _id: "",
          token: "",
          name: "",
        });
      }
      context.commit('setUserFromAuth', {
        _id: userId,
        token: token,
        name: name,
      });
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('_id');
      localStorage.removeItem('name');
      localStorage.removeItem('expiresIn');

      context.commit('setUserFromAuth', {
        _id: "",
        token: "",
        name: "",
      });
    }
    return;
  },
  logoutUser (context) {
    const token = localStorage.removeItem('token');
    const userId = localStorage.removeItem('_id');
    const name = localStorage.removeItem('name');
    localStorage.removeItem('expiresIn');

    localStorage.removeItem('waist');
    localStorage.removeItem('hip');
    localStorage.removeItem('thigh');
    localStorage.removeItem('inseam');
    localStorage.removeItem('email');

    context.commit('setUserFromAuth', {
      _id: userId,
      token: token,
      name: name,
    });
  },
  async forgotPasswordUser (context, payload) {
    try {
      let baseUrl = context.rootGetters.getDevBaseURL;
      const response = await axios.post(`${baseUrl}/account/reset/password/trigger`, {
        email: payload.email
      });
      return {
        message: response.data.message,
        code: response.data.code
      };
    } catch (err) {
      console.log(err);
      alert("Something went wrong.");
    }
  },
  changeName(context, payload) {
    localStorage.setItem('name', payload.name);
    context.commit('setUserFromAuth', {
      _id: payload._id,
      token: payload.token,
      name: payload.name,
    });
    return true;
  }
}
