<template>
  <div class="measurements-form-overlay"></div>
  <section class="measurement-sec-top">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="home-banner-form search-measurements-block" :class="{ 'home-banner-email': showEmailInput }">
            <div class="text-box text-box-small">
              <label :class="{ 'active': waist }" for="waistAbout">Waist<sup>*</sup></label>
              <input id="waistAbout" type="text" autocomplete="off" v-model="waist" />
              <span v-for="error in v$.waist.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-small">
              <label :class="{ 'active': hip }" for="hipsAbout">Hips<sup>*</sup></label>
              <input id="hipsAbout" type="text" v-model="hip" />
              <span v-for="error in v$.hip.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-small">
              <label :class="{ 'active': thigh }" for="thighAbout">Thigh<sup>*</sup></label>
              <input id="thighAbout" type="text" autocomplete="off" v-model="thigh" />
              <span v-for="error in v$.thigh.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-small">
              <label :class="{ 'active': inseam }" for="inseamAbout">Inseam<sup>*</sup></label>
              <input id="inseamAbout" type="text" autocomplete="off" v-model="inseam" />
              <span v-for="error in v$.inseam.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-large" v-if="showEmailInput">
              <label :class="{ 'active': email }" for="emailBanner">Email<sup>*</sup></label>
              <input id="emailBanner" type="text" autocomplete="off" v-model="email" />
              <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="home-form-cta">
              <button type="submit" class="fg-btn-primary w-100" @click="submitMeasurementForm">
                <span v-if="measurementThere">Update</span>
                <span v-else>Calculate</span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                    stroke="#0F2C66" stroke-width="2" />
                </svg>
              </button>
            </div>
          </div>
          <div class="measurement-btn-block">
            <a href="javascript:void(0);" class="measurement-btn">
              <span v-if="isLoggedIn">Measurements </span>
              <span v-else>Let's find your perfect fit! </span>
              <svg class="arrow-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9.22363L12 15.2236L18 9.22363" stroke="#F2C840" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="results-other-matches-block my-fav-block sec-nav-blur" v-if="displayedProducts.length">
          <div class="rom-title-block d-flex flex-wrap w-100">
            <div class="rom-title d-flex flex-wrap w-100">
              <h3 class="fs-40 fw-400">My Favorites</h3>
              <!-- <div class="rom-count fs-16">Showing 1 - 100 out of 100 Products</div> -->
            </div>
          </div>
          <div class="rom-list-block d-flex flex-wrap w-100">
            <product-card v-for="product in displayedProducts" @click="openProdDetailPopup(product._id)"
              :key="product._id"
              :productName="product.productName"
              :uniqueId="product.uniqueId"
              :brandName="product.brand.name"
              :fullSize="product.fullSize"
              :from="product.topPick"
              :_id="product._id"
              :isBookmark=1
              :productImages="product.productImages || []"
              :size="product.size"
            ></product-card>
            <div v-if="isLoading" class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="gtp-submit-retailer-block my-fav-block my-fav-no-results-block sec-nav-blur" v-else>
          <h2 class="fs-32">You currently have no products marked as favorites.</h2>
        </div>
        <measurement-block></measurement-block>
      </div>
    </div>
  </div>
  <product-detail-popup
    :brandName="singleProduct.brandName"
    :productName="singleProduct.productName"
    :productImages="singleProduct.productImages"
    :fullSize="singleProduct.fullSize"
    :productUrl="singleProduct.productUrl"
    :productDesc="singleProduct.productDesc"
    :stretch="singleProduct.stretch"
    :waistNotes="singleProduct.waistNotes"
    :hipNotes="singleProduct.hipNotes"
    :thighNotes="singleProduct.thighNotes"
    :inseamNotes="singleProduct.inseamNotes"
    :riseInProduct="singleProduct.rise"
    :isBookmark="singleProduct.isBookmark"
    :id="singleProduct._id"
    :isBookmarkBtn="singleProduct.isBookmarkBtn"
  ></product-detail-popup>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { gsap, TimelineMax, ScrollTrigger } from "gsap/all";
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, between, email } from '@vuelidate/validators';

import ProductCard from "../components/ui/ProductCard.vue";
import MeasurementBlock from "../components/layout/measurement/MeasurementBlock.vue";
import ProductDetailPopup from "../components/layout/products/ProductDetailPopup.vue";

export default {
components: {
  ProductCard,
  MeasurementBlock,
  ProductDetailPopup
},
data () {
  return {
    v$: useVuelidate(),
    isLoading: false,
    displayedProducts: [],
    waist: "",
    hip: "",
    thigh: "",
    inseam: "",
    email: "",
    showEmailInput: true,
    measurementThere: false,
    singleProduct: {}
  }
},
validations () {
  return {
    email: {
      required: helpers.withMessage('Email is required.', required),
      email: helpers.withMessage('Invalid email address.', email),
    },
    waist: this.waistValidation,
    hip: this.hipValidation,
    thigh: this.thighValidation,
    inseam: this.inseamValidation
  }
},
computed: {
  isLoggedIn: function() {
    return this.$store.getters.isAuth;
  },
  getToken: function () {
    return this.$store.getters.getToken;
  },
  waistValidation: function () {
    if (this.measurementType === "inches") { // inches
      return {
        required: helpers.withMessage('Waist is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 99))
      };
    } else { // cms
      return {
        required: helpers.withMessage('Waist is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 161))
      };
    }
  },
  hipValidation: function () {
    if (this.measurementType === "inches") { // inches
      return {
        required: helpers.withMessage('Hip is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 99))
      };
    } else { // cms
      return {
        required: helpers.withMessage('Hip is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 161))
      };
    }
  },
  thighValidation: function () {
    if (this.measurementType === "inches") { // inches
      return {
        required: helpers.withMessage('Thigh is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 99))
      };
    } else { // cms
      return {
        required: helpers.withMessage('Thigh is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 161))
      };
    }
  },
  inseamValidation: function () {
    if (this.measurementType === "inches") { // inches
      return {
        required: helpers.withMessage('Inseam is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 99))
      };
    } else { // cms
      return {
        required: helpers.withMessage('Inseam is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 161))
      };
    }
  }
},
methods: {
  submitMeasurementForm: async function () {
    this.v$.$touch();
    const validateForm = await this.v$.$validate();
    if (!validateForm) return;
    // this.$store.getters['pages/measurementFromUser'];
    this.$store.dispatch('products/measurementFromUser', {
      waist: this.waist,
      hip: this.hip,
      thigh: this.thigh,
      inseam: this.inseam,
      email: this.email,
      measurementType: this.measurementType ? this.measurementType : "inches"
    });
    // this.$router.push('/products');
    this.$router
      .push({path: "/products"})
      .then(() => {
        this.$router.go(0);
      })
    if (this.isLoggedIn == false) {
      // call api for measurement
      await axios.post(`${process.env.VUE_APP_API_BASE_URL}/measurement/save`, {
        waist: this.waist,
        hip: this.hip,
        thigh: this.thigh,
        inseam: this.inseam,
        email: this.email
      });
    }
    return;
  },
  openProdDetailPopup: async function (productId) {
    // Call api for product detail
    let measurement = this.$store.getters['products/getMeasurementFromLocal'];
    if (measurement && measurement.waist && measurement.hip && measurement.thigh && measurement.inseam) {
      let getToken = this.$store.getters.getToken;
      let headers = {};
      if (getToken) {
        headers.Authorization = "Bearer " + getToken;
      }
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/detail`, {
        productId: productId,
        waist: measurement.waist,
        hip: measurement.hip,
        thigh: measurement.thigh,
        inseam: measurement.inseam
      }, {
        headers
      })
        .then((product) => {
          // change URL
          $(document).on('click', '.goldie-product-block a:not(.gpb-wishlist)',function () {
            // window.history.pushState({}, '', `${process.env.VUE_APP_BASE_URL}/product/detail/${product.data.data.detail.slug}`);
            const currentHostname = window.location.hostname;
            const newPath = `/product/detail/${product.data.data.detail.slug}`;
            const newUrl = `https://${currentHostname}${newPath}`;
            window.history.pushState({}, '', newUrl);
          })
          this.emitter.emit("product-detail-data", {
            product: product.data.data.detail,
            fitNotes: product.data.data.fitNotes
          });
        })
    }
  },
},
mounted: async function () {
  gsap.registerPlugin(TimelineMax);

  let measurement = this.$store.getters['products/getMeasurementFromLocal'];
  this.waist = measurement.waist;
  this.hip = measurement.hip;
  this.thigh = measurement.thigh;
  this.inseam = measurement.inseam;
  this.email = measurement.email;

  if (this.waist && this.hip && this.thigh && this.inseam) {
    this.measurementThere = true;
  } else {
    this.measurementThere = false;
  }

  if (this.isLoggedIn) {
    this.showEmailInput = false;
  }
  if (this.isLoggedIn) {
    if (this.getToken) {
      let headers = {};
      headers.Authorization = "Bearer " + this.getToken;
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/account/my/profile`, { headers });
      if (response && response.status == 200 && response.data.code == 200) {
        if (response.data.data.favorites.length) {
          this.displayedProducts = response.data.data.favorites;
        }
      } else {
        return this.$router.push({path: "/"});
      }
    } else {
      return this.$router.push({path: "/"});
    }
  } else {
    return this.$router.push({path: "/"});
  }

  this.emitter.on("product-detail-data", (data) => {
    // this.singleProduct = data;
    this.singleProduct = {
      brandName: data.product.brand.name,
      productName: data.product.productName,
      productImages: data.product.productImages || [],
      fullSize: data.product.fullSize,
      productUrl: data.product.productUrl,
      productDesc: data.product.productDesc,
      rise: data.product.rise,
      stretch: data.product.stretch,
      waistNotes: data.fitNotes.waistFitNotes,
      hipNotes: data.fitNotes.hipFitNotes,
      thighNotes: data.fitNotes.thighFitNotes,
      inseamNotes: data.fitNotes.inseamFitNotes,
      isBookmark: data.product.isBookmark,
      isBookmarkBtn: data.product.isBookmark ? `<button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(${data.product.productUrl})">
              <span>Shop Now</span>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                  stroke="white" stroke-width="2" />
              </svg>
            </button>
            <a href="javascript:void(0);" id="${data.product._id}-heart-pdc" class="pdc-wishlist heart-pdc active-wishlist-pdc ${data.product._id}" @click="bookmarkFav(${data.product._id})">`
              :
            `<button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(${data.product.productUrl})">
              <span>Shop Now</span>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                  stroke="white" stroke-width="2" />
              </svg>
            </button><a href="javascript:void(0);" class="pdc-wishlist heart-pdc ${data.product._id}" id="${data.product._id}-heart-pdc" @click="bookmarkFav(${data.product._id})">`,
      _id: data.product._id
    };
    // console.log("this.singleProduct---------------");
    // console.log(this.singleProduct);
    // console.log(data);
  });

  // Jquery stuff
  $('body').removeClass('noscroll');
  document.title = "Find Jeans That Actually Fit | Goldie";
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', "Find Jeans That Actually Fit | Goldie");
  } else {
    const newMetaDescription = document.createElement('meta');
    newMetaDescription.setAttribute('name', "Find Jeans That Actually Fit | Goldie");
    newMetaDescription.setAttribute('content', "Find Jeans That Actually Fit | Goldie");
    document.head.appendChild(newMetaDescription);
  }
  // Jquery stuff
  $(document).ready(function () {
    $('.measurement-btn').on('click', function () {
      $('.measurement-btn .arrow-icon').toggleClass('rotate');
    })

    // Define onStart function
    function onReverseCompleteFunction() {
      $('.measurement-btn .arrow-icon').removeClass('rotate');
    }
    function onStartFunction() {
      $('.measurement-btn .arrow-icon').addClass('rotate');
    }
    document.querySelector(".measurement-btn").addEventListener("click", animateIt);

    var tl = new TimelineMax();
    tl.to(".search-measurements-block", 0.1, {
      // y: 0,
      display: 'flex'
    })
      .to(".measurement-btn-block", 0.1, {
        marginTop: 0,
        //ease:Power3.easeIn,
        onStart: onStartFunction,// Assign onStart callback function
        onReverseComplete: onReverseCompleteFunction // Assign onStart callback function
      }, "<");
    tl.reverse(-1)
    tl.reversed(true);

    function animateIt() {
      tl.reversed(!tl.reversed());
    }
    /*Measurement button form toggle*/
    /*Measurements form toggle onscroll*/
    $(window).bind('scroll', function () {
      var scrollsec1 = $(window).scrollTop();
      var elementOffsetscrollsec1 = $('.sec-nav-blur').offset().top;
      var currentElementOffsetscrollsec1 = (elementOffsetscrollsec1 - scrollsec1);
      if (currentElementOffsetscrollsec1 < 5) {
        //console.log('second section reached top of screen');
        $('.fg-navigation .top-nav .container').addClass('nav-bg-blur');
        $('.measurement-block-onscroll .measurement-btn').fadeIn();
      } else {
        $('.fg-navigation .top-nav .container').removeClass('nav-bg-blur');
        $('.measurement-block-onscroll .measurement-btn').fadeOut();
        $('.mb-os-form').hide();
      }
    });
    $('.measurement-block-onscroll .measurement-btn').on('click', function () {
      $('.mb-os-form').fadeToggle(100);
      /* $('body').addClass('noscroll'); */
      if ($('.measurements-form-overlay').is(':visible')) {
        $('.measurements-form-overlay').hide();
      } else {
        $('.measurements-form-overlay').show();
      }
      if ($('body').hasClass('noscroll')) {
        $('body').removeClass('noscroll')
      } else {
        $('body').addClass('noscroll')
      }
    });

    /*Measurements form toggle onscroll*/
    const showMeasurementAnim = gsap
      .from(".measurement-block-onscroll", {
        opacity: 0,
        paused: true,
        duration: 0.1,
      })
      .progress(1);

    ScrollTrigger.create({
      start: "top top",
      end: 999999,
      onUpdate: (self) => {
        self.direction === -1 ? showMeasurementAnim.play() : showMeasurementAnim.reverse();
      },
    });
    /*Navigation to hide on scroll*/
    const showAnim = gsap
      .from(".top-nav", {
        yPercent: -150,
        paused: true,
        duration: 0.3,
      })
      .progress(1);

    ScrollTrigger.create({
      trigger: ".sec-nav-blur",
      start: "top 100",
      end: 999999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
      // markers: true,
      // markers:{
      // 	startColor: '#f00',
      // 	endColor: '#9179CD',
      // 	fontSize: '2rem'
      // }
    });
    /*Navigation to hide on scroll*/
  })

}
}
</script>

<style>
</style>
