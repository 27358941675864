<template>
  <section class="home-sec-2 sec-nav-blur">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="count-block">
            <div class="count-single" id="home-number-1-block" data-aos="fade">
              <!-- <img :src="`${baseUrl}${illustrationOne}`" alt=""> -->
              <div id="home-number-1"></div>
              <h3 class="fs-20 text-capitalize">{{ illustrationOneTitle }}</h3>
              <h2 class="fs-64 fw-700 text-pink">{{ illustrationOneNumbers }}</h2>
            </div>
            <div class="count-single" id="home-number-2-block" data-aos="fade" data-aos-delay="200">
              <!-- <img :src="`${baseUrl}${illustrationTwo}`" alt=""> -->
              <div id="home-number-2"></div>
              <h3 class="fs-20 text-capitalize">{{ illustrationTwoTitle }}</h3>
              <h2 class="fs-64 fw-700 text-pink">{{ illustrationTwoNumbers }}</h2>
            </div>
            <div class="count-single" id="home-number-3-block" data-aos="fade" data-aos-delay="250">
              <!-- <img :src="`${baseUrl}${illustrationThree}`" alt=""> -->
              <div id="home-number-3"></div>
              <h3 class="fs-20 text-capitalize">{{ illustrationThreeTitle }}</h3>
              <h2 class="fs-64 fw-700 text-pink">{{ illustrationThreeNumbers }}</h2>
            </div>
            <div class="count-single" id="home-number-4-block" data-aos="fade" data-aos-delay="300">
              <!-- <img :src="`${baseUrl}${illustrationFour}`" alt=""> -->
              <div id="home-number-4"></div>
              <h3 class="fs-20 text-capitalize">{{ illustrationFourTitle }}</h3>
              <h2 class="fs-64 fw-700 text-pink">{{ illustrationFourNumbers }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scrolling-text-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="block-scrolling-text">
              <span>{{ floatingText }}</span>
              <span>{{ floatingText }}</span>
              <span>{{ floatingText }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollMagic from "scrollmagic";
import lottie from 'lottie-web';
import AOS from 'aos'
import 'aos/dist/aos.css' // Import AOS styles
gsap.registerPlugin(ScrollTrigger);

// console.log('GSAP version:', gsap.version);
// console.log('ScrollTrigger registered:', ScrollTrigger);
export default {
  props: ['illustrationOne', 'illustrationOneTitle', 'illustrationOneNumbers', 'illustrationTwo', 'illustrationTwoTitle', 'illustrationTwoNumbers', 'illustrationThree', 'illustrationThreeTitle', 'illustrationThreeNumbers', 'illustrationFour', 'illustrationFourTitle', 'illustrationFourNumbers', 'floatingText'],
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  async mounted() {
    AOS.init()
    /*Home count 1 animation scrollmagic*/
      // Load Lottie animation
      var count_animation_1 = lottie.loadAnimation({
          container: document.getElementById('home-number-1'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/home-number-1.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller_count_animation_1 = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#home-number-1', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.6 // Start animation when trigger is 75% down the viewport
      })
      .addTo(controller_count_animation_1)
      .on('enter', function () {
          // Add a delay of 5 seconds before starting the animation
          setTimeout(function () {
              count_animation_1.play();
              // Reset and play the animation when entering the scene
              count_animation_1.goToAndStop(0, true);
              count_animation_1.play();
          }, 0); // 5000 milliseconds = 5 seconds
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          count_animation_1.goToAndStop(count_animation_1.totalFrames - 1, true);
      });
    /*Home count 1 animation scrollmagic*/
    /*Home count 2 animation scrollmagic*/
      // Load Lottie animation
      var count_animation_2 = lottie.loadAnimation({
          container: document.getElementById('home-number-2'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/home-number-2.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller_count_animation_2 = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#home-number-2', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.6 // Start animation when trigger is 75% down the viewport
      })
      .addTo(controller_count_animation_2)
      .on('enter', function () {
          // Add a delay of 5 seconds before starting the animation
          setTimeout(function () {
              //count_animation_2.play();
              count_animation_2.goToAndStop(0, true);
              count_animation_2.play();
          }, 1000); // 5000 milliseconds = 5 seconds
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          count_animation_2.goToAndStop(count_animation_2.totalFrames - 1, true);
      });
    /*Home count 2 animation scrollmagic*/
    /*Home count 3 animation scrollmagic*/
      // Load Lottie animation
      var count_animation_3 = lottie.loadAnimation({
          container: document.getElementById('home-number-3'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/home-number-3.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller_count_animation_3 = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#home-number-3', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.6 // Start animation when trigger is 75% down the viewport
      })
      .addTo(controller_count_animation_3)
      .on('enter', function () {
          // Add a delay of 5 seconds before starting the animation
          setTimeout(function () {
              //count_animation_3.play();
              count_animation_3.goToAndStop(0, true);
              count_animation_3.play();
          }, 2500); // 5000 milliseconds = 5 seconds
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          count_animation_3.goToAndStop(count_animation_3.totalFrames - 1, true);
      });
    /*Home count 3 animation scrollmagic*/
    /*Home count 4 animation scrollmagic*/
      // Load Lottie animation
      var count_animation_4 = lottie.loadAnimation({
          container: document.getElementById('home-number-4'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/home-number-4.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller_count_animation_4 = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#home-number-4', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.6 // Start animation when trigger is 75% down the viewport
      })
      .addTo(controller_count_animation_4)
      .on('enter', function () {
          // Add a delay of 5 seconds before starting the animation
          setTimeout(function () {
              //count_animation_4.play();
              count_animation_4.goToAndStop(0, true);
              count_animation_4.play();
          }, 3500); // 5000 milliseconds = 5 seconds
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          count_animation_4.goToAndStop(count_animation_4.totalFrames - 1, true);
      });
    /*Home count 4 animation scrollmagic*/
  },
  methods: {
    // setupScrollAnimations() {
    //   const animations = [
    //     { target: "#home-number-1", trigger: "#home-number-1-block", path: '/animations/home-number-1.json', delay: 2 },
    //     { target: "#home-number-2", trigger: "#home-number-2-block", path: '/animations/home-number-2.json', delay: 4 },
    //     { target: "#home-number-3", trigger: "#home-number-3-block", path: '/animations/home-number-3.json', delay: 6 },
    //     { target: "#home-number-4", trigger: "#home-number-4-block", path: '/animations/home-number-4.json', delay: 8 }
    //   ];

    //   animations.forEach(anim => {
    //     this.LottieScrollTrigger({
    //       target: anim.target,
    //       trigger: anim.trigger,
    //       start: 'top 50%',
    //       end: 'top 50%',
    //       path: anim.path,
    //       scrub: 3,
    //       delay: anim.delay,
    //       pin: false
    //     });
    //   });
    // },
    // LottieScrollTrigger (vars) {
    //     let playhead = { frame: 0 },
    //     target = gsap.utils.toArray(vars.target)[0],
    //     speeds = { slow: "+=2000", medium: "+=1000", fast: "+=500" },
    //     st = { trigger: target, pin: true, start: "top top", end: speeds[vars.speed] || "+=1000", scrub: 1 },
    //     animation = lottie.loadAnimation({
    //       container: target,
    //       renderer: vars.renderer || "svg",
    //       loop: false,
    //       autoplay: false,
    //       path: vars.path
    //     });

    //   for (let p in vars) {
    //     st[p] = vars[p];
    //   }

    //   animation.addEventListener("DOMLoaded", function () {
    //     gsap.to(playhead, {
    //       duration: vars.duration || 0.5,
    //       delay: vars.delay || 5,
    //       frame: (animation.totalFrames - 1) * (vars.progress || 1),
    //       ease: vars.ease || "none",
    //       onUpdate: () => animation.goToAndStop(playhead.frame, true),
    //       scrollTrigger: st
    //     });

    //     ScrollTrigger.sort();
    //     ScrollTrigger.refresh();
    //   });

    //   return animation;
    // }
  }
}

</script>

<style></style>
